<template>
  <table-view
    class="dispatch-board"
    ref="tableView"
    url="/dispatch/bulletinBoard"
    :filterable="false"
    :filter-form="dataForm"
    :headers="headers"
    show-index
    :pageSize=100
    :split-index="2"
  >
    <el-form slot="search" @keyup.enter.native="query" inline>
      <el-form-item>
        <el-radio-group v-model="dataForm.day">
          <el-radio label="1">今日</el-radio>
          <el-radio label="2">明日</el-radio>
        </el-radio-group>
      </el-form-item>
      <!-- <el-form-item>
        <el-radio-group v-model="dataForm.sort">
          <el-radio label="time">时间</el-radio>
          <el-radio label="status">状态</el-radio>
          <el-radio label="car">车辆</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-radio-group v-model="dataForm.isAsc">
          <el-radio label="true">正序</el-radio>
          <el-radio label="false">逆序</el-radio>
        </el-radio-group>
      </el-form-item> -->
      <el-button @click="query" type="primary">查询</el-button>
    </el-form>
    <template slot="insertion">
      <el-table-column label="锁定状态" :width="80" header-align="center" align="center">
        <template slot-scope="{row}">
          <el-tag v-if="row.locked">
            <i class="el-icon-lock" @click="lockTask(row.id)"></i></el-tag>
          <el-tag v-else>
            <i class="el-icon-unlock" @click="lockTask(row.id)"></i></el-tag>
        </template>
      </el-table-column>
      <el-table-column label="需要车辆类型" :width="150" header-align="center" align="center">
        <template slot-scope="{row}">
          <car-list-dropdown-new
            :row="row"
            @close="query"
          ></car-list-dropdown-new>
        </template>
      </el-table-column>
      <el-table-column label="实际车辆" :width="150" header-align="center" align="center">
        <template slot-scope="{row}">
          <el-tag
            type="info"
          >{{row.number}}-{{row.carSize}}米</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        :width="150"
        prop="companyName"
        label="所属公司">
        <template slot-scope="{row}">
          <company-list-dropdown
            :companyOptions="companyOptions"
            :row="row"
            @close="query"
          ></company-list-dropdown>
        </template>
      </el-table-column>
    </template>
  </table-view>
</template>

<script>
import TableView from '@/components/templates/table-view'
import CarListDropdownNew from '@/components/templates/car-list-dropdown-new'
import CompanyListDropdown from '@/components/templates/company-list-dropdown'

export default {
  name: 'dispatch-board',

  components: { TableView, CarListDropdownNew, CompanyListDropdown },

  data () {
    return {
      companyOptions: [],
      headers: [
        { id: 1,
          prop: 'dispatchTime',
          label: '时间',
          width: 80,
          formatter: (row, column, cellValue) => {
            return cellValue.substring(11)
          },
          sortable: 'custom'
        },
        {
          id: 5,
          prop: 'dispatchTaskStatus',
          label: '状态',
          width: 100,
          formatter: (row, column, cellValue) => {
            // 1 待完成 2 进行中 3 已完成
            switch (cellValue) {
              case 1:
                return '待完成'
              case 2:
                return '进行中'
              case 3:
                return '已完成'
              case 4:
                return '失败'
              case 5:
                return '待下发'
              case 6:
                return '待确认'
              default:
                return '未知'
            }
          },
          sortable: 'custom'
        },
        // { id: 16, prop: 'statusInfo', label: '上传方量状态', width: 120 },
        { id: 10, prop: 'mixingTable', label: '拌台', width: 80, sortable: 'custom' },
        { id: 2, prop: 'constructionUnit', label: '施工单位', width: 100, sortable: 'custom' },
        { id: 3, prop: 'siteName', label: '工地名称', minWidth: 200 },
        { id: 4, prop: 'constructionPart', label: '施工部位', width: 120 },
        { id: 8, prop: 'gradation', label: '级配', width: 80 },
        { id: 11, prop: 'quantityNumber', label: '方量', width: 80 },
        { id: 9, prop: 'dangerNames', label: '危险源', width: 100, sortable: 'custom' },
        { id: 12, prop: 'siteAddress', label: '工地地址', width: 150 },
        { id: 14, prop: 'siteHeadName', label: '联系人', width: 80 },
        { id: 15, prop: 'siteHeadPhone', label: '联系人电话', width: 100 },
        { id: 6, prop: 'baseName', label: '所属基地', width: 100 },
        { id: 7, prop: 'executeBaseName', label: '执行基地', width: 100 }
      ],
      dataForm: {
        day: '1',
        sort: 'time',
        isAsc: 'false'
      }
    }
  },
  mounted () {
    this.$http({
      url: this.$http.adornUrl('/company/list'),
      method: 'post',
      data: {
        companyName: ''
      }
    }).then((data) => {
      this.companyOptions = data.datas
    })
  },
  methods: {
    query () {
      this.$refs.tableView.queryData()
    },
    lockTask (id) {
      console.log(id)
      this.$http({
        url: this.$http.adornUrl('/dispatch/locktask'),
        method: 'post',
        data: id
      }).then((data) => {
        if (data.datas == null) {
          this.$message({
            message: '锁定记录失败',
            type: 'warning'
          })
        } else {
          this.query()
          let msg = '锁定记录成功'
          if (!data.datas) {
            msg = '记录解锁成功'
          }
          this.$message({
            message: msg,
            type: 'success'
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.dispatch-board {
  .el-radio-group {
    border: 1px dashed #ddd;
    margin-right: 50px;
    padding: 8px 12px;
    border-radius: 4px;
  }
}
</style>
